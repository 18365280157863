export default [
  {
    key: 'amount',
    label: 'field.amount_diamond',
    rules: 'required',
    type: 'text',
    cols: 12,
  },
  {
    key: 'price',
    label: 'field.price',
    rules: 'required',
    type: 'currency',
    cols: 6,
  },
  {
    key: 'wholeSalePrice',
    label: 'field.wholeSalePrice',
    rules: 'required',
    type: 'currency',
    cols: 6,
  },
  {
    key: 'discountPrice',
    label: 'field.discount_price',
    type: 'currency',
    cols: 6,
  },
  {
    key: 'wholeSalePriceDiscount',
    label: 'field.wholeSaleDiscountPrice',
    type: 'currency',
    cols: 6,
  },
  {
    key: 'isDiscount',
    label: 'status.is_discount',
    type: 'checkbox',
    cols: 4,
  },
  {
    key: 'displayText',
    label: 'field.display_text',
    rules: 'required',
    type: 'text',
    cols: 12,
  },
  {
    key: 'packageType',
    label: 'field.package_type',
    rules: 'required',
    type: 'singleSelect',
    cols: 3,
    options: ['clashofclans'],
  },
  {
    key: 'image',
    label: 'field.image',
    type: 'single-image',
    cols: 12,
    ph: 100,
    pw: 100,
    initKey: 'mediaFile',
    fullWidth: false,
  },
  {
    key: 'isEnable',
    label: 'status.active',
    type: 'checkbox',
    cols: 12,
  },
  // {
  //   key: 'preorderPackage',
  //   label: 'status.preorder_package',
  //   type: 'checkbox',
  //   cols: 12,
  // },
]
